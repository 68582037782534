
import Vue from 'vue'
import { ethers } from 'ethers'

import { AnyswapV5ERC20ABI, AnyswapV4RouterABI } from '@/anyswap_config.json'
import { ERC20_ABI, FARM_REQUIRED_ALLOWANCE, APPROVE_AMOUNT } from '@/constants'
import { EcosystemId, ECOSYSTEMS, ChainId } from '@/ecosystem'

type BridgeToken = {
  symbol: string,
  isEther: boolean,
  src: {
    address: string | null,
    chainId: ChainId
  },
  dst: {
    address: string,
    chainId: ChainId
  },
  anyswapId: string,
  anyswapVersion: 'v2' | 'v3'
  iconSrc: string
}

type Network = {
  title: string,
  chainId: ChainId,
  iconSrc: string,
  explorer: string,
  dataseed: ethers.providers.Provider
}

type SwapType = 'DEPOSIT' | 'WITHDRAW'

type OperationStep = {
  isComplete: boolean,
  description: string,
  href?: string
}

const bridgeTokens: BridgeToken[] = [{
// moonriver
  symbol: 'TOAD',
  isEther: false,
  src: {
    address: '0x463e737d8f740395abf44f7aac2d9531d8d539e9',
    chainId: 56
  },
  dst: {
    address: '0x165DBb08de0476271714952C3C1F068693bd60D7',
    chainId: 1285
  },
  anyswapId: 'toadv5',
  anyswapVersion: 'v2',
  iconSrc: require('@/assets/tokens/bsc/TOAD.svg')
},
{
  symbol: 'BUSD',
  isEther: false,
  src: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    chainId: 56
  },
  dst: {
    address: '0x5d9ab5522c64e1f6ef5e3627eccc093f56167818',
    chainId: 1285
  },
  anyswapId: 'busdv5',
  anyswapVersion: 'v2',
  iconSrc: require('@/assets/tokens/bsc/BUSD.svg')
},
{
  symbol: 'BNB',
  isEther: true,
  src: {
    address: null,
    chainId: 56
  },
  dst: {
    address: '0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c',
    chainId: 1285
  },
  anyswapId: 'bnbv5',
  anyswapVersion: 'v2',
  iconSrc: require('@/assets/tokens/bsc/BNB.svg')
},
// moonbeam
{
  symbol: 'TOAD',
  isEther: false,
  src: {
    address: '0x463e737d8f740395abf44f7aac2d9531d8d539e9',
    chainId: 56
  },
  dst: {
    address: '0xF480f38C366dAaC4305dC484b2Ad7a496FF00CeA',
    chainId: 1284
  },
  anyswapId: '',
  anyswapVersion: 'v3',
  iconSrc: require('@/assets/tokens/bsc/TOAD.svg')
},
{
  symbol: 'BUSD',
  isEther: false,
  src: {
    address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    chainId: 56
  },
  dst: {
    address: '0xa649325aa7c5093d12d6f98eb4378deae68ce23f',
    chainId: 1284
  },
  anyswapId: 'busdv5',
  anyswapVersion: 'v2',
  iconSrc: require('@/assets/tokens/bsc/BUSD.svg')
},
{
  symbol: 'BNB',
  isEther: true,
  src: {
    address: null,
    chainId: 56
  },
  dst: {
    address: '0xc9baa8cfdde8e328787e29b4b078abf2dadc2055',
    chainId: 1284
  },
  anyswapId: 'bnbv5',
  anyswapVersion: 'v2',
  iconSrc: require('@/assets/tokens/bsc/BNB.svg')
},]

const bscEcosystem = ECOSYSTEMS[EcosystemId.BSC]
const moonriverEcosystem = ECOSYSTEMS[EcosystemId.Moonriver]
const moonbeamEcosystem = ECOSYSTEMS[EcosystemId.Moonbeam]

const bridgeNetworks: Network[] = [{
  title: 'BSC',
  chainId: bscEcosystem.chainId,
  iconSrc: require('@/assets/tokens/bsc/BNB.svg'),
  explorer: 'https://bscscan.com',
  dataseed: bscEcosystem.dataseed
},
{
  title: 'Moonriver',
  chainId: moonriverEcosystem.chainId,
  iconSrc: require('@/assets/tokens/moonriver/MOVR.png'),
  explorer: 'https://moonriver.moonscan.io',
  dataseed: moonriverEcosystem.dataseed
},
{
  title: 'Moonbeam',
  chainId: moonbeamEcosystem.chainId,
  iconSrc: require('@/assets/tokens/moonbeam/GLMR.png'),
  explorer: 'https://blockscout.moonbeam.network',
  dataseed: moonbeamEcosystem.dataseed
}]

async function delay(ms: number) {
  await new Promise(res => setTimeout(res, ms))
}

function toWei(number: string | number | null, decimals: number) {
  if (!number) {
    return ethers.BigNumber.from(0)
  }
  return ethers.utils.parseUnits(number.toString(), decimals)
}

function toEther(number: ethers.BigNumber, decimals: number) {
  return ethers.utils.formatUnits(number, decimals)
}

export default Vue.extend({
  data () {
    return {
      bridgeTokens: bridgeTokens,
      bridgeNetworks: bridgeNetworks,
      anyswapV2Config: <any> null,
      anyswapV3Config: <any> null,
      selectedToken: <BridgeToken | null> null,
      sourceNetwork: bridgeNetworks[0],
      destNetwork: bridgeNetworks[1],
      networkSlot: <'sourceNetwork' | 'destNetwork'> 'sourceNetwork',
      showNetworkDialog: false,
      showOperationDialog: false,
      operationInProgress: false,
      tokenDecimals: <number | null> null,
      tokenBalanceWei: <ethers.BigNumber | null> null,
      tokenAllowance: <ethers.BigNumber | null> null,
      bridgeAmount: <string | null> null,
      operationSteps: <OperationStep[]> [],
      snackbar: false,
      errorMsg: '',
      active: false,
      isLoading: true
    }
  },
  computed: {
    web3(): ethers.Signer | null {
      return this.$store.state.web3
    },
    myAddress(): string | null {
      return this.$store.state.address
    },
    chainId(): ChainId | null {
      return this.$store.state.chainId
    },
    tokenContractSrc(): ethers.Contract | null {
      if (!this.selectedToken || !this.selectedToken.src.address) {
        return null
      }
      const network = this.bridgeNetworks.find(n => this.selectedToken!.src.chainId == n.chainId)!
      return new ethers.Contract(this.selectedToken.src.address, ERC20_ABI, network.dataseed)
    },
    tokenContractDst(): ethers.Contract | null {
      if (!this.selectedToken) {
        return null
      }
      const network = this.bridgeNetworks.find(n => this.selectedToken!.dst.chainId == n.chainId)!
      return new ethers.Contract(this.selectedToken.dst.address, ERC20_ABI, network.dataseed)
    },
    tokenContractSigner(): ethers.Contract | null {
      if (!this.tokenContractSrc || !this.web3) {
        return null
      }
      return new ethers.Contract(this.tokenContractSrc.address, ERC20_ABI, this.web3)
    },
    tokenBalance(): string | null {
      if (!this.tokenBalanceWei || !this.tokenDecimals) {
        return null
      }
      return toEther(this.tokenBalanceWei, this.tokenDecimals)
    },
    balanceMessage(): string | null {
      if (this.tokenBalance === null) {
        return null
      }
      return `Balance: ${this.tokenBalance} ${this.selectedToken!.symbol}`
    },
    validationStatus(): string | null {
      if (this.chainId != this.sourceNetwork.chainId) {
        return `Wallet not connected to ${this.sourceNetwork.title} network`
      }

      if (this.bridgeAmount &&
          this.tokenBalanceWei &&
          this.tokenDecimals) {
        const bridgeAmountWei = toWei(this.bridgeAmount, this.tokenDecimals)
        const tokenBalanceWeiBn = ethers.BigNumber.from(this.tokenBalanceWei.toString())
        if (bridgeAmountWei.gt(tokenBalanceWeiBn)) {
          return 'Insufficient balance'
        }

        const minimumWei = toWei(this.anyswapLimits.MinimumSwap, this.tokenDecimals)
        if (bridgeAmountWei.lt(minimumWei)) {
          return `Minimum amount is ${this.anyswapLimits.MinimumSwap} ${this.currentSymbol}`
        }
      }

      return null
    },
    needsApproval(): boolean {
      if (!this.anyswapTokenConfig || !this.anyswapTokenConfig.router) {
        return false
      }
      const tokenAllowance = parseInt((this.tokenAllowance ?? ethers.BigNumber.from(0)).toString())
      return tokenAllowance < FARM_REQUIRED_ALLOWANCE
    },
    currentChainTokens(): BridgeToken[] {
      return bridgeTokens.filter(t =>
        (t.src.chainId == this.sourceNetwork.chainId && t.dst.chainId == this.destNetwork.chainId) ||
        (t.dst.chainId == this.sourceNetwork.chainId && t.src.chainId == this.destNetwork.chainId))
    },
    // TODO: types
    anyswapTokenConfig(): any {
      if (this.selectedToken == null || this.anyswapV2Config == null || this.anyswapV3Config == null) {
        return null
      }

      if (this.selectedToken.anyswapVersion == 'v2') {
        return this.anyswapV2Config[this.selectedToken.dst.chainId][this.selectedToken.anyswapId]
      } else {
        // TODO: v3 ether tokens
        const configEntry = this.anyswapV3Config.UNDERLYINGV2[this.selectedToken.src.chainId][this.selectedToken.src.address!.toLowerCase()]
        const destConfig = configEntry.destChains[this.selectedToken.dst.chainId]
        const anyswapConfig = {
          MinimumSwap: destConfig.MinimumSwap,
          MaximumSwap: destConfig.MaximumSwap,
          SwapFeeRate: destConfig.SwapFeeRatePerMillion * 1e-2,
          MinimumSwapFee: destConfig.MinimumSwapFee,
          BigValueThreshold: destConfig.BigValueThreshold
        }
        return {
          SrcToken: {
            ...anyswapConfig,
            anyToken: configEntry.anyToken
          },
          DestToken: {
            ...anyswapConfig,
            anyToken: destConfig.anyToken
          },
          router: configEntry.router,
          srcChainID: this.selectedToken.src.chainId
        }
      }
    },
    swapType(): SwapType | null {
      if (this.anyswapTokenConfig == null) {
        return null
      }

      return this.sourceNetwork.chainId == parseInt(this.anyswapTokenConfig.srcChainID) ? 'DEPOSIT' : 'WITHDRAW'
    },
    anyswapLimits(): any {
      if (this.anyswapTokenConfig == null) {
        return {
          MinimumSwap: null,
          MaximumSwap: null,
          SwapFeeRate: null,
          MinimumSwapFee: null,
          BigValueThreshold: null
        }
      } else if (this.swapType == 'DEPOSIT') {
        return {
          ...this.anyswapTokenConfig.SrcToken
        }
      } else {
        return {
          ...this.anyswapTokenConfig.DestToken
        }
      }
    },
    displayedAnyswapLimits(): any {
      if (this.selectedToken && this.selectedToken.symbol == 'TOAD') {
        return {
          ...this.anyswapLimits,
          MaximumSwap: Math.min(this.anyswapLimits.MaximumSwap, 195000)
        }
      }
      return this.anyswapLimits
    },
    currentSymbol(): string | null {
      if (this.selectedToken == null) {
        return null
      }

      return this.selectedToken.symbol
    }
  },
  watch: {
    currentChainTokens(val: BridgeToken[]) {
      if (val == null || val.length == 0) {
        this.selectedToken = null
      } else if (this.selectedToken == null) {
        this.selectedToken = val[0]
      } else {
        this.selectedToken = val.find(t => t.symbol == this.selectedToken!.symbol) ?? null
      }

      this.tokenDecimals = null
      this.tokenBalanceWei = null
      this.tokenAllowance = null
      setTimeout(() => this.refreshData())
    },
    selectedToken(val) {
      this.tokenDecimals = null
      this.tokenBalanceWei = null
      this.tokenAllowance = null
      setTimeout(() => this.refreshData())
    }
  },
  async created() {
    const [response1, response2] = await Promise.all([
      fetch('https://bridgeapi.multichain.org/v2/serverInfo/chainid'),
      fetch('https://bridgeapi.multichain.org/v3/serverinfoV3?chainId=all')
    ])
    this.anyswapV2Config = await response1.json()
    this.anyswapV3Config = await response2.json()
    this.isLoading = false
  },
  async mounted() {
    this.active = true
    // wait for wallet connection
    await delay(100) // TODO: use events

    // let sourceNetwork = this.bridgeNetworks.find(n => n.chainId == this.chainId)

    // if (!sourceNetwork) {
    //   sourceNetwork = this.bridgeNetworks.find(n => n.chainId == this.$store.getters.ecosystem.chainId)
    // }
    // if (!sourceNetwork) {
    //   sourceNetwork = this.bridgeNetworks[0]
    // }

    // const otherNetwork = this.bridgeNetworks.find(n => n != sourceNetwork)


    // Defaults to BSC->GLMR, remove this and uncomment the code above to return to previous implementation
    let sourceNetwork = this.bridgeNetworks[0]
    let otherNetwork = this.bridgeNetworks[2]
    //


    this.sourceNetwork = sourceNetwork
    this.destNetwork = otherNetwork!
    this.selectedToken = this.currentChainTokens.find(() => true) ?? null

    while (this.active) {
      try {
        await this.refreshData()
      } catch (e) {
        console.error(e)
      }

      await delay(5000)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.active = false
    next()
  },
  beforeDestroy() {
    this.active = false
  },
  methods: {
    selectBridgeNetwork(network: Network) {
      try {
        const previousNetwork = this[this.networkSlot]
        this[this.networkSlot] = network
        const otherSlot = this.networkSlot == 'sourceNetwork' ? 'destNetwork' : 'sourceNetwork'
        if (this[otherSlot] === network) {
          this[otherSlot] = previousNetwork
        }

        if (this.chainId !== null &&
            network.chainId !== this.chainId &&
            this.networkSlot == 'sourceNetwork') {
          // TODO: add ethereum network to metamask if missing
          this.$store.dispatch('requestNetworkChange', network.chainId)
        }
      } finally {
        this.showNetworkDialog = false
      }
    },
    setMaxTokens() {
      this.bridgeAmount = this.tokenBalance?.toString() ?? null
    },
    async approve() {
      const sourceNetwork = this.sourceNetwork
      const tokenContract = sourceNetwork.chainId == this.selectedToken!.src.chainId
        ? this.tokenContractSrc!
        : this.tokenContractDst!
      const tx = await tokenContract.populateTransaction.approve(this.anyswapTokenConfig.router, APPROVE_AMOUNT)
      await this.$store.dispatch('safeSendTransaction', { tx, targetChainId: sourceNetwork.chainId })
      setTimeout(() => this.refreshData())
    },
    async startBridgeOperation() {
      const tokenConfig = this.anyswapTokenConfig
      const swapType = this.swapType
      const sourceNetwork = this.sourceNetwork
      const destNetwork = this.destNetwork
      const selectedToken = this.selectedToken!
      const symbol = this.currentSymbol
      const tokenDecimals = tokenConfig.SrcToken.Decimals
      const bridgeAmount = this.bridgeAmount
      const bridgeAmountWei = toWei(bridgeAmount, tokenDecimals)
      this.showOperationDialog = true
      this.operationInProgress = true
      this.bridgeAmount = null
      this.operationSteps = [{
        isComplete: false,
        description: `Deposit ${symbol} on ${sourceNetwork.title}`
      }]

      try {
        let tx: ethers.providers.TransactionResponse
        if (swapType == 'DEPOSIT' && selectedToken.anyswapVersion == 'v2') {
          if (selectedToken.isEther && sourceNetwork.chainId == selectedToken.src.chainId) {
            tx = await this.web3!.sendTransaction({
              value: bridgeAmountWei,
              to: tokenConfig.SrcToken.DepositAddress
            })
          } else {
            tx = await this.tokenContractSigner!.transfer(tokenConfig.SrcToken.DepositAddress, bridgeAmountWei)
          }
        } else if (swapType == 'WITHDRAW' && selectedToken.anyswapVersion == 'v2') {
          const anyswapERC20Contract = new ethers.Contract(tokenConfig.DestToken.ContractAddress, AnyswapV5ERC20ABI, this.web3!)
          tx = await anyswapERC20Contract.Swapout(bridgeAmountWei, this.myAddress)
        } else if (swapType == 'DEPOSIT' && selectedToken.anyswapVersion == 'v3') {
          const anyswapRouterContract = new ethers.Contract(tokenConfig.router, AnyswapV4RouterABI, this.web3!)
          const destChainId = ethers.BigNumber.from(destNetwork.chainId)
          tx = await anyswapRouterContract.anySwapOutUnderlying(
            tokenConfig.SrcToken.anyToken.address,
            this.myAddress,
            bridgeAmountWei,
            destChainId
          )
        } else if (swapType == 'WITHDRAW' && selectedToken.anyswapVersion == 'v3') {
          const anyswapRouterContract = new ethers.Contract(tokenConfig.router, AnyswapV4RouterABI, this.web3!)
          const destChainId = ethers.BigNumber.from(destNetwork.chainId)
          tx = await anyswapRouterContract['anySwapOut(address,address,uint256,uint256)'](
            tokenConfig.DestToken.anyToken.address,
            this.myAddress,
            bridgeAmountWei,
            destChainId
          )
        } else {
          throw new Error()
        }

        const depositHref = `${sourceNetwork.explorer}/tx/${tx.hash}`
        this.operationSteps[this.operationSteps.length - 1].href = depositHref
        await tx.wait()
        this.markLastOperationStepCompleted()

        this.operationSteps.push({
          isComplete: false,
          description: `Submit transaction to bridge network`
        })

        let addedAnyswapOperation = false
        while (true) {
          const response = await fetch(`https://bridgeapi.multichain.org/v2/history/details?params=${tx.hash}`)
          const operationStatus = await response.json()
          if (operationStatus.info &&
              operationStatus.info.txid &&
              !addedAnyswapOperation) {
            const anyswapHref = `https://anyswap.net/explorer/tx?params=${tx.hash}`
            this.markLastOperationStepCompleted(anyswapHref)
            this.operationSteps.push({
              isComplete: false,
              description: `Withdraw ${symbol} on ${destNetwork.title}`
            })
            addedAnyswapOperation = true
          }

          if (operationStatus.info &&
              operationStatus.info.swaptx &&
              await destNetwork.dataseed.getTransaction(operationStatus.info.swaptx)) {
            const withdrawHref = `${destNetwork.explorer}/tx/${operationStatus.info.swaptx}`
            this.markLastOperationStepCompleted(withdrawHref)
            break
          }

          await delay(3000)
        }
      } catch (e: any) {
        this.showOperationDialog = false
        if (e.code == 4001) {
          this.errorMsg = 'User rejected transaction'
          this.snackbar = true
        } else {
          throw(e)
        }
      } finally {
        this.operationInProgress = false
      }
    },
    markLastOperationStepCompleted(href?: string) {
      const lastOperationStep = this.operationSteps[this.operationSteps.length - 1]
      lastOperationStep.isComplete = true
      if (href) {
        lastOperationStep.href = href
      }
    },
    async refreshData() {
      if (!this.web3 || !this.myAddress) {
        return
      }

      if (this.selectedToken && this.anyswapTokenConfig) {
        const selectedToken = this.selectedToken
        const tokenConfig = this.anyswapTokenConfig
        const sourceNetwork = this.sourceNetwork
        const tokenContract = sourceNetwork.chainId == selectedToken.src.chainId
          ? this.tokenContractSrc!
          : this.tokenContractDst!
        const myAddress = this.myAddress

        let tokenDecimals
        let tokenBalanceWei
        let tokenAllowance = null
        if (selectedToken.isEther && sourceNetwork.chainId == selectedToken.src.chainId) {
          tokenDecimals = 18
          tokenBalanceWei = await sourceNetwork.dataseed.getBalance(myAddress)
        } else {
          tokenDecimals = await tokenContract.decimals()
          tokenBalanceWei = await tokenContract.balanceOf(myAddress)
          if (tokenConfig.router) {
            tokenAllowance = await tokenContract.allowance(myAddress, tokenConfig.router)
          }
        }

        if (selectedToken === this.selectedToken &&
            sourceNetwork == this.sourceNetwork &&
            myAddress === this.myAddress) {
          this.tokenDecimals = tokenDecimals
          this.tokenBalanceWei = tokenBalanceWei
          this.tokenAllowance = tokenAllowance
        }
      }
    }
  },
  filters: {
    formatNumber(n: number | null) {
      if (n == null) return ''
      return n.toLocaleString(undefined, { maximumFractionDigits: 8 })
    },
    formatPercent(n: number | null) {
      if (n == null) return '%'
      return n.toLocaleString(undefined, { style: 'percent', maximumFractionDigits: 4 })
    }
  }
})
